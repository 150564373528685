<template>
  <div class="orderlist-page">
    <van-sticky>
    <van-dropdown-menu style="z-index: 9999">
      <van-dropdown-item v-model="activityStatus" :options="activityoption" @change="changeActivity"/>
      <van-dropdown-item v-model="cardStatus" :options="statusoption" @change="changeStatus"/>
    </van-dropdown-menu>
    </van-sticky>

    <no-data v-if="activity_list.length<=0"></no-data>
    <van-pull-refresh v-model="isLoading" success-text="刷新成功" @refresh="onRefresh" v-else>
      <van-list finished-text="加载完毕" v-model="isloading" :finished="finished"  @load="loadData">
        <div class="activity" >
          <div class="activity-item" v-for="(item,index) in activity_list" :key="index" @click.stop="toTicket(item.id)">

            <van-cell title-class="top-cell flex flex-row">
              <template slot="title">
       
                     <van-image
                 width="3.73333rem" height="2.66667rem"
                  :src="item.banner_image"
                  fit="cover"
          
                >
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>


                <div class="text-wrap flex flex-column">
                  <div class="flex flex-row flex-jusbet">
                       <span class="t1 text-base font-14 clamp-2 m">{{item.activity_name}}</span>
                        <p class="status text-red" v-if="item.activity_status == 1">{{item.activity_status_note}}</p>
                        <p class="status text-green" v-else-if="item.activity_status==2">{{item.activity_status_note}}</p>
                        <p class="status text-grey" v-else>{{item.activity_status_note}}</p>
                                
                  </div>
                  
                  <span class="t2 text-grey font-12 clamp-2">时间：{{item.start_time}}开始</span>
                  <span class="t2 text-grey font-12 clamp-2">地址：{{item.address}}</span>
                </div>
               
              </template>
            </van-cell>

            <van-cell title-class="bot-left-cell  " value-class="bot-right-cell">
                <template slot="title">
                   <span>票价</span>
                   <span class="text-theme3 ml5">{{item.amount==0?'免费':'￥'+item.amount}}</span>
                </template>
                <template slot="default">
                  <span class="text-red" v-if="item.pay_status == 0">{{item.pay_status_note}}</span>
                  <span class="text-theme3"  v-else-if="item.pay_status == 1">{{item.pay_status_note}}</span>
                  <span class="text-theme2" v-else-if="item.pay_status == 2">{{item.pay_status_note}}</span>
                  <span class="text-grey" v-else>{{item.pay_status_note}}</span>
               </template>
            </van-cell>

          </div>
          
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import {
  DropdownMenu,
  DropdownItem,
  List,
  Row,
  Col,
  Panel,
  PullRefresh,
  Cell,
  CellGroup,
  Loading,
  Sticky
} from "vant";
import { activity_list,activity_mylist } from "@/request/api";

export default {
  components: {
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [List.name]: List,
    [Row.name]: Row,
    [Col.name]: Col,
    [Panel.name]: Panel,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Loading.name]: Loading,
    [Sticky.name]:Sticky
  },
  watch:{
      cardStatus(e){
          
      }

  },
  data() {
    return {
      activityStatus: -1,
      cardStatus: -1,
      activityoption: [
        { text: "所有活动", value: -1 },
        { text: "未开始", value: 1 },
        { text: "进行中", value: 2 },
        { text: "已结束", value: 4 }
      ],
      statusoption: [
        { text: "所有", value: -1 },
        { text: "待付款", value: 0 },
        { text: "待验票", value: 1 },
        { text: "已完成", value: 2 }
      ],
      page: 1,
      activity_list: [],
      isLoading: false,
      finished:false,
      isloading:false
    };
  },
  mounted: function() {
    //卡状态
    if(this.$route.query.status || this.$route.query.status==0){
        this.cardStatus = Number(this.$route.query.status);
    }
    this.initData();
  },
  methods: {
      changeActivity(value){
          this.activityStatus = value;
          this.initData();
      },
      changeStatus(value){
          this.cardStatus = value;
          this.initData();
      },
      initData(){
          this.page = 1;
          this.activity_list = [];
          this.finished = false;
          this.loadData();
      },
      async loadData() {
          if(this.finished){
              return;
          }
          if(this.isLoading !=true){
              this.loading = true;
          }
          
        // 获取售票列表
        await activity_mylist({pay_status: this.cardStatus,page: this.page,activity_status:this.activityStatus}).then(res => {
          
            if(res.code == 1){
                //  还有更多数据page++
                if (res.data.has_more == true){
                    this.page++;
                    this.finished = false
                } else {
                    //没有数据了
                    this.finished = true
                }
                
                if(this.isLoading == true){      
                   this.activity_list = res.data.data;
                }else{
                   this.activity_list = this.activity_list.concat(res.data.data);
                }
                
                 // 加载状态结束
                this.loading = false;
                this.isLoading = false;
            } else {
                this.$dialog({message:res.msg});
            }
        }).catch(err => {
            // 加载状态结束
            this.loading = false;
            this.isLoading = false;

            this.$dialog({
                message: "获取订单数据失败，请重新刷新"
            })
        })

        
        
    },
    onRefresh() {
   
      this.isLoading = true;
      this.finished = false;
      this.page = 1;
      this.loadData();
    },
    toTicket: function(id) {
      this.$router.push({ path: "/activity/enroll/moremessage" ,query:{id: id }});
    }
  }
};
</script>
<style lang="less">
.orderlist-page {
  margin-bottom: 5px;

  .activity-item {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-top: 10px;
    padding: 0 !important;
    
    .top-cell{
       justify-content: space-between;
       .van-image{
         
       
       }
       .text-wrap{
          flex: 1;
          margin-left: 10px;
          justify-content: space-between;
          .t1{
              height: 50px;
          }
       }
       .status{
          min-width: 45px;
        align-items: flex-start;
       
         
       }
    }
  }

}
</style>
